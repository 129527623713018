.modal-overlay {
    position: fixed; /* 固定位置 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* 半透明の黒 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 最前面に表示 */
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001; /* モーダル自体も最前面に */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
  }